import { React, useState } from "react";
import "./App.css";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Icon } from "leaflet";
import LocationData from "./locations.json";
import icon1 from "./images/map-pin.svg";
import "bootstrap/dist/css/bootstrap.min.css";
// import map from "./json/flamenco_spain.json";



const ShowsIcon = new Icon({
  iconUrl: icon1,
  iconSize: [35, 35],
});

function App() {
  const [activeShows, setActiveShows] = useState(null);
  
  return (
    <MapContainer
    
      // center={[39.5919255, -5.2850753]}
      center={[39.5647039, -2.9521642]}
      zoom={6}
      scrollWheelZoom={false}
      zoomControl={false}
      doubleClickZoom={false}
      dragging={false}
      zoomSnap={false}
      zoomDelta={false}
      trackResize={true}
      touchZoom={false}
      
      
      
      
    > 
      <TileLayer
      attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
    
      />

     
      {LocationData.map((eachData) => (
        <Marker 
          key={eachData.Id}
          title={eachData.Location}
          position={[eachData.Latitude, eachData.Longitude]}
          eventHandlers={{
            click: () => {
              setActiveShows(eachData);
              window.open(eachData.websiteurl)
            },
          }}
          icon={ShowsIcon}
          
        />
      ))}

   
    </MapContainer>
  );
}

export default App;
